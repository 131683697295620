import React from 'react';
import {graphql} from 'gatsby';
import tw, {styled, theme} from 'twin.macro';
import Layout from '../components/Layout';
import data from '../config/site_data.json';
import SEO from '../components/Seo';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {Container, Section} from '../components/Grid';
import {Heading} from '../components/Typography';
import BookNowForm from '../components/shared/BookNowForm';
import PageHero from '../components/shared/hero';
import FAQ from '../components/Page/FAQ';
import PageDescription from '../components/Page/office-cleaning/PageDescription';
import {
  Description,
  CardComponent,
} from '../components/Page/office-cleaning/Included';
import BookNowController from '../components/BookNowController';
import { StyledButton } from '../components/Page/home-cleaning/RegularCleaning';
const InfoWrapper = styled(Section)`
  ${tw`relative`}

  @media ${props => props.theme.screens.xl} {
    margin-top: 180px;
  }
`;

const Content = styled.div`
  @media ${props => props.theme.screens.sm} {
    max-width: 80%;
    margin: 0 auto;
  }
  @media ${props => props.theme.screens.md} {
    max-width: 80%;
    margin: 0;
  }
`;

const StyledHeading = styled(Heading)`
  ${tw`text-secondary`}

  @media ${props => props.theme.screens.md} {
    font-size: 40px;
    line-height: 127%;
  }
`;

const Text = styled.p`
  ${tw`my-6  text-secondary`}
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  a {
    text-decoration: underline;
    color: #039259;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 19px;
    line-height: 31px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 150px;

  @media ${props => props.theme.screens.md} {
    position: absolute;
    width: 100%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 200px;
  }
  @media ${props => props.theme.screens.lg} {
    width: 50%;
    right: 20%;
  }

  @media ${props => props.theme.screens.xl} {
    left: calc(100% - ((100% - ${theme('screens').xl}) / 2));
    transform: translate(-100%, -50%);
  }
`;

const CleaningOfCommonAreas = props => {
  const {language, t} = useI18next();
  const {
    background,
    backgroundMobile,
    backgroundTablet,
    faq,
    formBackground,
    descriptionBackground,
  } = props.data;

  const {heading, text, info, button_text} = data.cleaning_of_common_areas;
  const {title, description_one, description_two} = info;

  return (
    <Layout headerStyle="windowWashing">
      <SEO
        title={t('cleaning_of_common_areas_seo_title')}
        description={t('cleaning_of_common_areas_seo_description')}
        keywords={t('cleaning_of_common_areas_seo_keywords', {
          returnObjects: true,
        })}
        lang={language}
      />
      <PageHero
        imgPosition={'100% 0%'}
        imgMobilePosition={'100% 90%'}
        content={{
          heading,
          text,
          button: button_text,
        }}
        imgObj={{
          background,
          backgroundMobile: backgroundMobile,
          backgroundTablet: backgroundTablet,
        }}
      />
      <InfoWrapper>
        <Container>
          <Content>
            <StyledHeading>{t(title)}</StyledHeading>
            <Text dangerouslySetInnerHTML={{__html: t(description_one)}} />
            {/* <Text dangerouslySetInnerHTML={{__html: t(description_two)}} /> */}
          </Content>
        </Container>
      </InfoWrapper>
      <Container>
        <PageDescription button background={descriptionBackground} />
        <FAQ faqs={faq.edges} headingProps={{marginLeft: 0}} />
        <div className="flex flex-wrap" style={{marginTop: '120px'}}>
          <div className="flex flex-1 w-full lg:w-1/2 p-4 items-center justify-center h-100">
            <Description
              dangerouslySetInnerHTML={{
                __html: t(description_two),
              }}
            />
          </div>
          <div className="flex-1 w-full lg:w-1/2 p-4">
            <CardComponent
              heading={t('cleaning_of_common_areas_included_heading')}
            >
              <Description
                dangerouslySetInnerHTML={{
                  __html: t('cleaning_of_common_areas_included_content'),
                }}
              />
              <div style={{ marginTop: 50 }}>
              <BookNowController>
                <StyledButton>{t('office_cleaning_book')}</StyledButton>
              </BookNowController>
              </div>
             
            </CardComponent>
          </div>
        </div>
        <BookNowForm
          background={formBackground}
          heading={t('cleaning_of_common_areas_book_now_heading')}
        />
      </Container>
    </Layout>
  );
};

export default CleaningOfCommonAreas;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {
          in: [
            "common"
            "cleaning_of_common_areas"
            "seo_cleaning_of_common_areas"
            "office_cleaning"
          ]
        }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    background: file(relativePath: {eq: "common-area-hero.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundMobile: file(relativePath: {eq: "common-area-hero-mobile.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundTablet: file(relativePath: {eq: "common-area-hero-tablet.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    formBackground: file(relativePath: {eq: "common-area-form.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    descriptionBackground: file(
      relativePath: {eq: "common-area-description.png"}
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          quality: 90
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    faq: allContentfulFaq(
      sort: {order: ASC, fields: createdAt}
      filter: {pageTag: {eq: "common-area"}, node_locale: {eq: $language}}
    ) {
      edges {
        node {
          childContentfulFaqAnswerTextNode {
            answer
          }
          question
          id
        }
      }
    }
  }
`;
